
import Shell from "@shared/Shell";
import useTransactions from "@hooks/useTransactions";
import {FLOORS_LOWER_CASE} from '@constants/floors';
import useRecentReconciliations from "@hooks/useRecentReconciliations";
import TransactionsSummary from "@shared/TransactionsSummary";
import { useEffect, useState } from "react";
import log from "loglevel";
import dateFormat from "dateformat";
import {Spinner} from "@shared/Spinner";
import {RefreshButton} from "@shared/Buttons";

export function Reconciliations() {

  const {isLoading, data, refetch} = useRecentReconciliations();

  const [middleData, setMiddleData] = useState([]);

  const recTabs = [
    { label: 'Ground', child: <Blank /> },
    { label: 'Middle', child: <FloorReconciliation refetch={refetch} floor={1} dates={middleData}/>},
    { label: 'Top', child: <Blank />},
  ];

  useEffect(() => {

    if (!isLoading) {
      console.log("recs:", data);
      setMiddleData(data[1]);
    }
  
  return () => {
  };
}, [isLoading, data]);

  return (
      <Shell pageIndex={0}>
        <div>
          {
            isLoading ? 
            <div className="grid justify-items-center">
              <h1 className="text-2xl">Loading reconciliation dates...</h1>
              <div className="bg-white text-slate-500 h-32 w-32">
                <Spinner spin={true}/>
              </div> 
            </div>:
            <Tabs tabs={recTabs} defaultIndex={1}/>
          }
          
        </div>
      </Shell>
  );
}

function Blank() {
  return (<></>);
}

type FloorReconciliationProps = {
  dates: Date[];
  floor: number;
  refetch: () => void;
};

const FloorReconciliation = ({ dates, floor, refetch}: FloorReconciliationProps) => {

  const [showR, setShowR] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  function showNext() {

    setEnd(new Date());
    setStart(new Date(dates[0]));
    setShowR(true);
  }
 
  function show(index: number) {
    
    setEnd(new Date(dates[index]));
    setStart(new Date(dates[index+1]));
    setShowR(true);
  } 

  async function refresh() {

    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }

  return(
    <div>
      <div className="flex justify-center items-center align-middle mt-4 mb-10">
        <span className="text-2xl">Recent reconciliation dates for <b>{FLOORS_LOWER_CASE[floor]}</b> floor</span>
        <RefreshButton onClick={refresh} showSpinner={showSpinner}/>
      </div>
      <div className="mb-8 ">
      {
        dates.length > 0 ? 
        <button
          type="button"
          key="next"
          className="rounded bg-indigo-50 px-2 py-1  font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 mr-2"
          onClick={showNext}
        >
          Next
        </button> : 
        <></>
      }
      {
        dates.map((date, index) => {

          if (index < dates.length - 1)
            return (
              <button   
                type="button"
                key={index}
                className="rounded bg-indigo-50 px-2 py-1 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 mr-2"
                onClick={()=>{show(index)}}
              >
                {dateFormat(date, "ddd, mmmm dS HH:MM")}
              </button>
            );
          return <span key={index}></span>
        })
      }
      </div>
      {
        showR ?
        <ReconciliationView start={start} end={end} floor={floor} /> :
        <div className="text-xl">Select a date</div>
      }
    </div>
  );
}

type ReconciliationViewProps = {
  start: Date;
  end: Date;
  floor: number;
};

const ReconciliationView = ({ start, end, floor}: ReconciliationViewProps) => {
  const {isLoading, data, refetch} = useTransactions(start, end, floor);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {

    if (!isLoading) {
      log.debug("rec data:", data);
    }
  
    return () => {
    };
  }, [isLoading, data]);

  async function refresh() {
    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }

  return (
    <>
    {
      isLoading ?
      <div className="grid justify-items-center ">
        <div className="bg-white text-slate-500 h-32 w-32">
          <Spinner spin={true}/>
        </div> 
      </div> :
      <div>
        <div className="flex justify-center items-center">
          <h2>
            Reconciliation between {dateFormat(start, "ddd, mmmm dS HH:MM")} and {dateFormat(end, "ddd, mmmm dS HH:MM")}
          </h2>
          {/**<RefreshButton onClick={refresh} showSpinner={showSpinner}/>*/}
        </div>
        <TransactionsSummary transactions={data}/>
      </div>
    }
    </>
  );
}

type Tab = {
  label: string;
  child: JSX.Element;
};

type TabsProps = {
  tabs: Tab[];
  defaultIndex: number;
};

const Tabs = ({ tabs, defaultIndex }: TabsProps) => {

  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const [child, setChild] = useState(tabs[defaultIndex].child);

  useEffect(() => {
    
    setChild(tabs[tabIndex].child)

    return () => {
    };
  }, [tabIndex, setChild, tabs]);

  return (
    <div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs[defaultIndex].label}
          >
            {tabs.map((tab) => (
              <option key={tab.label}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <button
                  key={tab.label}
                  onClick={()=>setTabIndex(index)}
                  className={
                    tabIndex === index
                      ? 'border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  }
                  aria-current={tabIndex === index ? 'page' : undefined}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        {child}
      </div>        
    </div>
  );
}

export default Reconciliations;
