import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "@screens/ErrorPage";
import { useState } from 'react'
import log, { LogLevelNumbers } from "loglevel";
import { AdminAuthProvider } from "@components/ContextProviders/AdminAuthContext";
import { AdminAPIServiceProvider } from "@components/ContextProviders/AdminAPIServiceContext";
import { QueryClient, QueryClientProvider } from 'react-query'
import { Amplify } from 'aws-amplify';
import Reconciliations from "@components/Reconciliations";

import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { COGNITO_APP_ID, COGNITO_POOL_ID } from "@constants/urls";
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { NavigationProvider } from "@shared/ContextProviders/NavigationContext";


import './App.css';
import '@aws-amplify/ui-react/styles.css';


const queryClient = new QueryClient()

try {
  const level: LogLevelNumbers = 1; //process.env.REACT_APP_LOG_LEVEL;

  console.log("Log level set to " + level);
  log.setLevel(level);
} catch (error) {
  console.log("LOG LEVEL COULD NOT BE READ");
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Reconciliations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "home",
    element: <Reconciliations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signout",
    element: <SignOut />,
  }
]);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_APP_ID,
      userPoolId: COGNITO_POOL_ID,
    }
  }
});

export function App({ signOut, user }: WithAuthenticatorProps) {

  const [pages] = useState([
    { name: 'Reconciliations', href: 'home', index: 0}
  ]);

  return (
    <div className="App">
      
      <NavigationProvider pages={pages}>
        <AdminAuthProvider>
          <AdminAPIServiceProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </AdminAPIServiceProvider>
        </AdminAuthProvider>
      </NavigationProvider>

    </div>
  );
}

function SignOut() {

  const navigate = useNavigate();

  try {
    signOut();
    navigate("/");
  } catch (error) {
    log.warn('error signing out: ', error);
  }

  return (<></>);
}

export default withAuthenticator(App, { hideSignUp: true });
