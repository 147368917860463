import { ReactNode, createContext } from "react";
import log from "loglevel";
import axios from "axios";
import { JWT } from 'aws-amplify/auth';
import useAdminAuth from "@hooks/useAdminAuth";
import { API_URL } from "@constants/urls";

const AdminAPIServiceContext = createContext<AdminAPIServiceContextValue | undefined>(undefined);


type AdminAPIServiceContextProps = {
  children?: ReactNode
};

export type AdminAPIServiceContextValue = {
  fetch: (call: string, data: {}) => Promise<any>;
};

export const AdminAPIServiceProvider = ({ children }: AdminAPIServiceContextProps) => {
  //const [auth, setAuth] = useState<AuthContextValue | undefined>(undefined);
  const jwt = useAdminAuth();

  const fetch = async (call: string, data: {}) => {
    log.debug("fetch ", call, data);
    
    return jwt?.getJwtToken()
    .then((token: any) => {
      //log.info("API Service: ",token);
      return fetchData(token.tokens.idToken, call, data);
    })
    .catch(function (error: any) {
      log.info("auth error:" + error);
    })
  }

  const value: AdminAPIServiceContextValue = {
    fetch
  }

  return (
    <AdminAPIServiceContext.Provider value={value}>
      {children}
    </AdminAPIServiceContext.Provider>
  );
};

const POST = "POST";
//const GET = "GET";

function fetchData(jwt: JWT, call: string, data: {}) {

  return callWithAuthorization(POST, jwt, call, {}, data)
  .then(function(value) {

    //log.info("fetched: " + JSON.stringify(value.data));
    return value.data;
  })
  .catch(function(error) {
    console.error(error);
  });
}

async function callWithAuthorization(method: string, token:JWT, resource:string, params:{}, data:{}) {
  log.info("calling", resource);

  const options = {
    method: method,
    data: data,
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    },
    url: API_URL + resource //convertMapToURLParams(params)
  };  

  //log.info("callWithAuthorization 2:" + JSON.stringify(options));

  return axios(options);
}
/*
function convertMapToURLParams(params:{<string, any>}) {
  if (params === undefined) return "";

  if  (Object.keys(params).length === 0) return "";

  return (
    "?" +
    Object.keys(params)
      .map(key => key + "=" + params[key])
      .join("&")
  );
}*/

export default AdminAPIServiceContext;
