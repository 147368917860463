
import { useState, useEffect} from 'react'
import log from "loglevel";
import dateFormat from "dateformat";

import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";
import Table from "@shared/Table";
import {CASH, DEBIT, CREDIT, CONTRA, CREDIT_FEE, DEBIT_FEE} from '@constants/payment_types'
import {GOOD, REFUNDED} from '@constants/status_types'
import { formatPrice } from "@shared/Utils";
import currency from 'currency.js';

type TransactionsProps = {
  transactions: CompletedTransaction[];
};

const TransactionsSummary = ({ transactions }: TransactionsProps) => {

  return (
    <>
      <div>
        <TransactionsByTypeTable label="Cash" transactions={transactions} paymentType={CASH} statusType={GOOD}/>

        <TransactionsByTypeTable label="Contra" transactions={transactions} paymentType={CONTRA} statusType={GOOD}/>

        <TransactionsByTypeTable label="Credit" transactions={transactions}  paymentType={CREDIT} statusType={GOOD}/>
        <TransactionsByTypeTable label="Debit" transactions={transactions}  paymentType={DEBIT} statusType={GOOD}/>
        <TransactionsByTypeTable label="Refunded Debit" transactions={transactions}  paymentType={DEBIT} statusType={REFUNDED}/>

        <Summary transactions={transactions} />
      </div>
    </>
  );
}

const columns = [
  {
    "id": "stallholder",
    "label": "Dealer",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "",
    "format": (cell: any, row: {}, index: number) => { 
      return cell;
    }
  },
  {
    "id": "price",
    "label": "Net",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1 "},
    "headerStyle": "pl-2 py-1 pr-4",
    "format": (cell: any, row: {}, index: number) => { 

      const transaction = row as CompletedTransaction;
      if (transaction.status === GOOD) {
        if (transaction.paymentType === DEBIT)
          return formatPrice(transaction.price - (transaction.price * DEBIT_FEE));
        if (transaction.paymentType === CREDIT)
          return formatPrice(transaction.price - (transaction.price * CREDIT_FEE));
        else
          return formatPrice(transaction.price);
      }
      else if (transaction.status === REFUNDED) {
        if (transaction.paymentType === DEBIT) {
          return (transaction.price * DEBIT_FEE) * 2;
        }
      }
      return <div className="">-</div>
    }
  },
  {
    "id": "stockid",
    "label": "Stock ID",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "description",
    "label": "Description",
    "cellStyle": (row: number) => {return "bg-white py-1"},
    "headerStyle": "py-1",
    "format": (cell: any, row: {}, index: number) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "timestamp",
    "label": "Time",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}, index: number) => { 

      const date = new Date(cell);

      //if (isToday(date)) return <>Today {dateFormat(date, "HH:MM")}</>;
      //else if (isYesterday(date)) return <>Yesterday {dateFormat(date, "HH:MM")}</>;
      return <>{dateFormat(date, "ddd, mmmm dS HH:MM")}</>;
    }
  }
]

type TransactionsByTypeTableProps = {
  label: string;
  transactions: CompletedTransaction[];
  paymentType: number;
  statusType: number;
};

function filterTransactionsByType(paymentType: number, statusType: number, transactions: CompletedTransaction[], parent: CompletedTransaction[]) {

  transactions.forEach((transaction: CompletedTransaction) => {

    if (transaction.paymentType === paymentType && transaction.status === statusType) {
      //log.info("in");
      parent.push(transaction);
    }
  });
}

function sortOldestFirst(transactions: CompletedTransaction[]){

  return transactions.sort((obj1: Object, obj2: Object) => {
    const key1 = "timestamp" as keyof typeof obj1;
    const key2 = "timestamp" as keyof typeof obj2;

    if (obj1[key1] > obj2[key2]) {
        return 1;
    }

    if (obj1[key1] < obj2[key2]) {
        return -1;
    }

    return 0;
  });  
}

const TransactionsByTypeTable = ({ label, transactions, paymentType, statusType }: TransactionsByTypeTableProps) => {
  
  const [filteredTransactions, setFilteredTransactions] = useState<CompletedTransaction[]>([]);
  

  useEffect(() => {
    log.info("TransactionsByTypeTable", label, transactions.length)
    var filtered : Array<CompletedTransaction> = [];
    filterTransactionsByType(paymentType, statusType, transactions, filtered);
    filtered = sortOldestFirst(filtered);
    setFilteredTransactions(filtered);

  return () => {
  };
}, [transactions, paymentType, statusType, label]);   

  return (
    <>
      <h1 className="mb-4 mt-6 text-2xl font-bold text-left">{label}</h1>
      <Table data={filteredTransactions} columns={columns} />
      {
        transactions.length > 0 ? <></> :
        <div>NONE</div>
      }
    </>
  );
}

const summaryCols = [

  {
    "id": "label",
    "label": "",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    "headerStyle": "pl-2 py-1",
  },
  {
    "id": "gross",
    "label": "Gross",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2 py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },
  {
    "id": "fees",
    "label": "Fees",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2 py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },
  {
    "id": "net",
    "label": "Net",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2  py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },

]

type SummaryProps = {
  transactions: CompletedTransaction[];
};

type SummaryType = {
  label: string;
  gross: number;
  fees: number;
  net: number;
};

const Summary = ({ transactions }: SummaryProps) => {

  const [summary, setSummary] = useState<SummaryType[]>([]);

  useEffect(() => {
    
      log.info("Summary");
      const s = {
        cash: 0.0,
        credit: 0.0,
        debit: 0.0,
        contra: 0.0,
        refundedDebit: 0.0
      }


      processTransactions(transactions, s);

      const debitFee = currency(s.debit).multiply(DEBIT_FEE);
      const debitRefunded = currency(s.refundedDebit).multiply(DEBIT_FEE).multiply(2);
      const totalDebitFees = debitFee.subtract(debitRefunded);
      const debitNet = currency(s.debit).subtract(totalDebitFees);

      const creditFee = currency(s.credit).multiply(CREDIT_FEE);
      const creditNet = currency(s.credit).subtract(creditFee);
      
      const totalCard = currency(s.debit).add(s.credit);
      const totalCardNet = debitNet.add(creditNet);
      const totalCardFee = totalCard.subtract(totalCardNet);

      const newSummary : Array<SummaryType> = [];
      newSummary.push({label: "Debit", gross: s.debit, fees: totalDebitFees.value, net: debitNet.value});
      newSummary.push({label: "Credit", gross: s.credit, fees: creditFee.value, net: creditNet.value});
      newSummary.push({label: "Card total", gross: totalCard.value, fees: totalCardFee.value, net: totalCardNet.value});
      newSummary.push({label: "Cash", gross: s.cash, fees: 0.00, net: s.cash});
      newSummary.push({label: "Total", gross: s.cash + s.debit + s.credit, fees: totalCardFee.value, net: s.cash + debitNet.value + creditNet.value});
      newSummary.push({label: "Contra", gross: s.contra, fees: 0.00, net: s.contra});

      setSummary(newSummary);
    
    return () => {
    };
  }, [transactions]); 

  function processTransactions(transactions: CompletedTransaction[], s:{cash: number, credit: number, debit: number, contra: number, refundedDebit: number}) {
    log.info("processTransactions");
    transactions.forEach((transaction: CompletedTransaction) => {
      if (transaction.status === GOOD) {

        if (transaction.paymentType === CASH)
          s.cash += transaction.price;
        else if (transaction.paymentType === DEBIT)
          s.debit += transaction.price;
        else if (transaction.paymentType === CREDIT)
        s.credit += transaction.price;
        else if (transaction.paymentType === CONTRA)
        s.contra += transaction.price;
        else {
          log.error("Sales processed unknown payment type:", transaction.paymentType);
        }
      }
      else if (transaction.status === REFUNDED && transaction.paymentType === DEBIT) {
        s.refundedDebit += transaction.price;
      }
    });    
  }


  return (
    <div className="mt-24 w-96">
      <h1 className="font-extrabold text-2xl ">Summary</h1>
      <Table data={summary} columns={summaryCols} />
    </div>
  )
}

export default TransactionsSummary;
