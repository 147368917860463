import { useRouteError } from "react-router-dom";
import Shell from "@shared/Shell";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
      <Shell pageIndex={-1}>
        <div>
          <h1>Sorry, an errorr occurred</h1>
          <p>
            {error.message}
          </p>
        </div>
      </Shell>
  );
}